import { lazy } from 'react';

const UsersPage = lazy(() => import('../user/pages/users-page'));
const UnitsPage = lazy(() => import('../units/pages/units-page'));
const SettingsPage = lazy(() => import('../settings/pages/settings-page'));
const IngredientsPage = lazy(() => import('../ingredients/pages/ingredients-page'));
const IngredientsDetails = lazy(() => import('../ingredients/pages/ingredient-details'));
const CategoriesPage = lazy(() => import('../categories/pages/categories-page'));
const RecipesCreatePage = lazy(() => import('../recipes/pages/recipes-create-page'));
const CategoriesDetails = lazy(() => import('../categories/pages/categories-details'));
const AccessoriesPage = lazy(() => import('../accessories/accessories-page'));
const AccessoriesDetails = lazy(() => import('../accessories/components/accessories-details'));
const UnitDetails = lazy(() => import('../units/pages/unit-details'));
const RegisterPage = lazy(() => import('../authentication/pages/register-page'));
const AnalyticsPage = lazy(() => import('../analytics/analytics-page'));
const MobileUsersPage = lazy(() => import('../analytics/pages/mobile-users-page'));
const GuidedModePage = lazy(() => import('../analytics/pages/guided-mode-page'));
const AppUsagePage = lazy(() => import('../analytics/pages/app-usage-page'));
const AppConnectionsPage = lazy(() => import('../analytics/pages/app-connections-page'));
const MobileUsersIndex = lazy(() => import('../analytics/pages/mobile-users-index'));
const OsPieChartGraphPage = lazy(() => import('../analytics/pages/os-pie-chart-graph-page'));
const CountryPieChartGraph = lazy(() => import('../analytics/pages/country-pie-chart-graph'));
const CustomerAccountActivationPage = lazy(
  () => import('../authentication/pages/customer-account-activation-page'),
);
const ResetSuccessfulPage = lazy(
  () => import('../authentication/pages/reset-password/reset-successful-page'),
);

export {
  AccessoriesDetails,
  AccessoriesPage,
  AnalyticsPage,
  AppConnectionsPage,
  AppUsagePage,
  CategoriesDetails,
  CategoriesPage,
  CountryPieChartGraph,
  CustomerAccountActivationPage,
  GuidedModePage,
  IngredientsDetails,
  IngredientsPage,
  MobileUsersIndex,
  MobileUsersPage,
  OsPieChartGraphPage,
  RecipesCreatePage,
  RegisterPage,
  ResetSuccessfulPage,
  SettingsPage,
  UnitDetails,
  UnitsPage,
  UsersPage,
};
